import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import EmailAutocomplete from "@components/AutocompleteEmail/Email";
import useFormError from "@components/Form/hooks/useFormError";
import styles from "@styles/components/Form/autocomplete-email.module.scss";

/**
 * @typedef {Object} Props
 * @property {string} label - The label for the Email input field.
 * @property {string} name - The name of the Email input field.
 */

/**
 * A component to render an Email Autocomplete input field within a form.
 * @param {Props} props - The props object.
 * @returns {JSX.Element} The rendered Email Autocomplete input field wrapped in a form control.
 */
const FormEmailAutocomplete = (props) => {
	const { control } = useFormContext();
	const { markupError } = useFormError(props.name);

	return (
		<div className={styles.customEmailAutocompleteInputWrapper}>
			<label htmlFor={props.name}>{props.label}</label>
			<Controller
				name={props.name}
				control={control}
				render={({ field }) => <EmailAutocomplete {...field}{...props} />}
			/>

			{markupError}
		</div>
	);
};

export default FormEmailAutocomplete;
